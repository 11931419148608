import React, { useContext, useState, Suspense, useEffect } from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import "./App.css";
import { globalContext, GlobalContextProvider } from "./Context.js";
import { getLocalContent, isVisibleById, showInfoDialog } from "./utils.js";
import logoEn from "./assets/images/logoen.webp";
import logoBg from "./assets/images/logobg.webp";
import logoEvent from "./assets/images/logoEvent.webp";
import logoBeauty from "./assets/images/logoBeauty.webp";

const Modal = React.lazy(() => import("./Modal.js"));
const WeddingCafe = React.lazy(() => import("./WeddingCafe.js"));
const EventCafe = React.lazy(() => import("./EventCafe.js"));
const BeautyCafe = React.lazy(() => import("./BeautyCafe.js"));

function showTermsAndConditions(dispatch, state) {
  import("./terms-and-conditions.js").then((component) => {
    component =
      state.language === "en" ? component.default.en : component.default.bg;
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Terms & Conditions",
          "Условия за ползване"
        )}
      </span>,
      component,
      true
    );
  });
}

function showCorporateInformation(dispatch, state) {
  import("./corporate-information.js").then((component) => {
    component =
      state.language === "en" ? component.default.en : component.default.bg;
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Corporate Information",
          "Корпоративна информация"
        )}
      </span>,
      component,
      true
    );
  });
}

function showPrivacyPolicy(dispatch, state) {
  import("./privacy-policy.js").then((component) => {
    showInfoDialog(
      dispatch,
      <span className="text-red">
        {getLocalContent(
          state.language,
          "Privacy Policy",
          "Политика за личните данни"
        )}
      </span>,
      component.default,
      true
    );
  });
}

const SITE_TO_USE = "w";

export const useLangPath = () => {
  let { state } = useContext(globalContext);
  return (path) => `/${state.language}/${path}`;
};
const LinksToOtherSites = (props) => {
  const { state } = useContext(globalContext);

  const [ttSite2, setTtSite2] = useState();
  const [ttSite3, setTtSite3] = useState();
  const [logoSite2, setLogoSite2] = useState();
  const [logoSite3, setLogoSite3] = useState();

  useEffect(() => {
    const ttWed = getLocalContent(
      state.language,
      "Visit our weddings & personal events site - Wedding Café",
      "Посетете сайта ни за сватби и лични събития - Сватбено Кафе"
    );
    const ttBeauty = getLocalContent(
      state.language,
      "Visit our beaty salon site - Beauty Café Boutique",
      "Посетете сайта на салона ни за красота - Бюти Кафе Бутик"
    );
    const ttCorp = getLocalContent(
      state.language,
      "Visit our corporate events site - Event Café Boutique",
      "Посетете сайта ни за корпоративни събития - Ивент Кафе Бутик"
    );
    switch (state.site.id) {
      case "c":
        setTtSite2(ttWed);
        setTtSite3(ttBeauty);
        setLogoSite2(getLocalContent(state.language, logoEn, logoBg));
        setLogoSite3(logoBeauty);
        break;
      case "w":
        setTtSite2(ttCorp);
        setTtSite3(ttBeauty);
        setLogoSite2(logoEvent);
        setLogoSite3(logoBeauty);
        break;
      case "b":
        setTtSite2(ttWed);
        setTtSite3(ttCorp);
        setLogoSite2(getLocalContent(state.language, logoEn, logoBg));
        setLogoSite3(logoEvent);
        break;
      default:
    }
  }, [state.language, state.site]);
  return (
    <div className="logo-group">
      <a
        href={state.hrefSite2}
        rel="noopener noreferrer"
        target="_blank"
        className="text-red tooltip-parent"
        onClick={(e) => {
          document.activeElement.blur();
        }}
      >
        <img className="blink-on-hover py-0" src={logoSite2} alt={ttSite2} />
        <div className="tooltip-on-hover">{ttSite2}</div>
      </a>
      <a
        href={state.hrefSite3}
        rel="noopener noreferrer"
        target="_blank"
        className="text-red tooltip-parent"
        onClick={(e) => {
          document.activeElement.blur();
        }}
      >
        <img className="blink-on-hover py-0" src={logoSite3} alt={ttSite3} />
        <div className="tooltip-on-hover right">{ttSite3}</div>
      </a>
    </div>
  );
};
const Fallback = (props) => {
  if (props.hidden) {
    return (
      <div className="px-2">
        <div className="card bg-image h-80-vh hover my-2" />
      </div>
    );
  }
  return (
    <div className="container page">
      <div className="px-2">
        <div className="card bg-image h-80-vh hover my-2" />
      </div>
    </div>
  );
};

const MainPage = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [showCookiesBanner, setShowCookiesBanner] = useState(
    () => !localStorage.getItem("cookiesAccepted")
  );
  const [site] = useState(SITE_TO_USE);
  useEffect(() => {
    let payload = {};
    switch (SITE_TO_USE) {
      case "c":
        payload = {
          id: "c",
          logoEn: logoEvent,
          logoBg: logoEvent,
          href: "https://www.eventcafeboutique.com",
          hrefSite2: "https://www.svatbenocafe.com",
          hrefSite3: "https://www.facebook.com/beautycafeboutiquesofia/",
          title: "Event Cafe",
          titleBg: "Ивент Кафе",
          fb: "https://www.facebook.com/SVATBENOCAFE",
          mail: "eventcafeboutique.com",
        };
        break;
      case "w":
        payload = {
          id: "w",
          logoEn: logoEn,
          logoBg: logoBg,
          hrefSite2: "https://www.eventcafeboutique.com",
          href: "https://www.svatbenocafe.com",
          hrefSite3: "https://www.facebook.com/beautycafeboutiquesofia/",
          title: "Wedding Cafe",
          titleBg: "Сватбено Кафе",
          fb: "https://www.facebook.com/SVATBENOCAFE",
          mail: "svatbenocafe.com",
        };
        break;
      case "b":
        payload = {
          id: "b",
          logoEn: logoBeauty,
          logoBg: logoBeauty,
          hrefSite3: "https://www.eventcafeboutique.com",
          hrefSite2: "https://www.svatbenocafe.com",
          href: "https://www.beautycafeboutique.bg/",
          title: "Beauty Boutique Cafe",
          titleBg: "Бюти Кафе",
          fb: "https://www.facebook.com/beautycafeboutiquesofia/",
        };
        break;
      default:
    }
    dispatch({
      type: "site",
      payload: payload,
    });
  }, []);
  useEffect(() => {
    const onBodyClick = (e) => {
      if (isVisibleById("cookiesBanner")) {
        localStorage.setItem("cookiesAccepted", true);
        setShowCookiesBanner(false);
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);
  return (
    <Router>
      <div className="" id="mainPage">
        {site === "w" && (
          <Suspense fallback={<Fallback />}>
            <WeddingCafe />
          </Suspense>
        )}
        {site === "c" && (
          <Suspense fallback={<Fallback />}>
            <EventCafe />
          </Suspense>
        )}
        {site === "b" && (
          <Suspense fallback={<Fallback />}>
            <BeautyCafe />
          </Suspense>
        )}
      </div>
      <footer className="footer">
        <LinksToOtherSites />
        <NavLink
          className="text-red"
          as={NavLink}
          to={`/${state.language}/contacts`}
          onClick={(e) => {
            document.activeElement.blur();
            window.scrollTo(0, 0);
          }}
        >
          &#128140; office&#64;eventcafeboutique.com
        </NavLink>
        <div className="small mt-3">
          <a
            href="#/"
            className="text-red d-inline-block mx-2"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showTermsAndConditions(dispatch, state);
            }}
          >
            {getLocalContent(
              state.language,
              "Terms & Conditions",
              "Общи условия"
            )}
          </a>
          <a
            href="#/"
            className="text-red d-inline-block mx-2"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showPrivacyPolicy(dispatch, state);
            }}
          >
            {getLocalContent(
              state.language,
              "Privacy Policy",
              "Политика за личните данни"
            )}
          </a>
          <a
            href="#/"
            className="text-red d-inline-block mx-2"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showCorporateInformation(dispatch, state);
            }}
          >
            {getLocalContent(
              state.language,
              "Corporate Information",
              "Корпоративна информация"
            )}
          </a>
        </div>
        <div className="small pb-2 mt-2">
          Event Cafe Boutique &copy; 2010-{new Date().getFullYear()} All rights
          reserved.
        </div>

        <div className="developed-by">
          Developed by
          <a
            href="https://www.insentico.com"
            rel="noopener noreferrer"
            target="_blank"
            className="text-red ml-1"
          >
            Insentico Ltd.
          </a>
        </div>
        <div className="ads small font-italic d-none">
          <a
            href="https://www.easycryptobot.com"
            rel="noopener noreferrer"
            target="_blank"
            className="text-red"
          >
            easycryptobot
          </a>
        </div>
      </footer>
      {showCookiesBanner && (
        <div className="banner" id="cookiesBanner">
          <div className="flex-grow-1">
            {getLocalContent(
              state.language,
              <>
                We use cookies and analytical tools to provide you with the best
                experience only while using our website. We do not use or share
                them for advertising purposes.
                <br />
                By continuing, you accept our
                <a
                  href="#/"
                  className="text-red d-inline-block mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    showTermsAndConditions(dispatch, state);
                  }}
                >
                  Terms and conditions
                </a>
                and
                <a
                  href="#/"
                  className="text-red d-inline-block ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    showPrivacyPolicy(dispatch, state);
                  }}
                >
                  Privacy policy
                </a>
                .
              </>,
              <>
                Ние използваме бисквитки и аналитични инструменти, за да ви
                предоставим най-доброто изживяване докато разглеждате сайта ни.
                Не ги използваме с цел реклама.
                <br />
                Продължавайки, приемате нашите
                <a
                  href="#/"
                  className="text-red d-inline-block mx-2"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    showTermsAndConditions(dispatch, state);
                  }}
                >
                  Общи условия
                </a>
                и
                <a
                  href="#/"
                  className="text-red d-inline-block ml-2"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    showPrivacyPolicy(dispatch, state);
                  }}
                >
                  Политика за личните данни
                </a>
                .
              </>
            )}
          </div>
          <div className="d-flex align-items-end justify-content-end mt-3 mt-md-0 ml-3">
            <div className="btn btn-red px-4">OK</div>
          </div>
        </div>
      )}
      <Suspense fallback={<Fallback hidden />}>
        <Modal
          id="infoDialog"
          header={state.infoDialog.header}
          content={state.infoDialog.content}
          large={state.infoDialog.large}
          closeFn={state.infoDialog.closeFn}
        />
      </Suspense>
      {/* <ModalConfirm
        id={"confirmDialog"}
        header={state.confirmDialog.header}
        content={state.confirmDialog.content}
        func={() => {
          if (state.confirmDialog.func()) {
            state.confirmDialog.func();
          }
        }}
        large={state.confirmDialog.large}
      /> */}
    </Router>
  );
};

const App = (props) => {
  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
};

export default App;
