import React, { createContext, useReducer } from "react";

function getDefaultLanguage() {
  const pathname = window.location.pathname;
  let language = localStorage.getItem("language");
  if (pathname.indexOf("/en") !== -1) {
    language = "en";
  } else if (pathname.indexOf("/bg") !== -1) {
    language = "bg";
  } else {
    language = localStorage.getItem("language");
  }
  return language ? language : "bg";
}

let initialState = {
  language: getDefaultLanguage(),
  infoDialog: { header: "", content: "" },
  confirmDialog: { header: "", content: "", func: null },
  site: {
    id: "",
    logoEn: "",
    logoBg: "",
    href: "",
    hrefSite2: "",
    hrefSite3: "",
    title: "",
    titleBg: "",
    fb: "",
    mail: "",
  },
  contactType: "general",
};
let globalContext = createContext(initialState);

let globalContextReducer = (state, action) => {
  switch (action.type) {
    case "language":
      localStorage.setItem("language", action.payload);
      return { ...state, language: action.payload };
    case "infoDialog":
      return { ...state, infoDialog: action.payload };
    case "confirmDialog":
      return { ...state, confirmDialog: action.payload };
    case "site":
      return { ...state, site: action.payload };
    case "contactType":
      return { ...state, contactType: action.payload };
    default:
      return state;
  }
};

const GlobalContextProvider = (props) => {
  let [state, dispatch] = useReducer(globalContextReducer, initialState);
  let value = { state, dispatch };

  return (
    <globalContext.Provider value={value}>
      {props.children}
    </globalContext.Provider>
  );
};

export { GlobalContextProvider, globalContext, getDefaultLanguage };
